import api from '@/libs/axios'

export default {
  async getList(params) {
    let response = await api.get('/admin/user-report', {params});
    return response
  },
  async getAll(params) {
    let response = await api.get('/admin/user-report/all', {params});
    return response
  },
  convertLocation(location){
    switch(location){
      case 1: return "Arena (new)"
      case 2: return "Pronun"
      case 3: return "Breaking"
      case 4: return "Classic Arena"
      default: return ""
    }
  },
  convertOption(type){
    switch(type){
      case 1: return "Error in original text"
      case 2: return "Inaccurate or ineffective score"
      case 3: return "Loading slowly"
      case 4: return "Not user-friendly"
      case 5: return "Opponent report"
      case 6: return "Others"
      default: return ""
    }
  }
}
