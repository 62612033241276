<template>
  <div>
    <b-table-simple caption-top responsive bordered class="pt-2">
      <b-thead head-variant="light">
        <b-th>Date</b-th>
        <b-th>Feedback Area</b-th>
        <b-th>Location</b-th>
        <b-th>Option</b-th>
        <b-th>Description</b-th>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item,index) in items" :key="index">
          <b-td>{{ item.createdAt }}</b-td>
          <b-td>{{ convertLocation(item.location) }}</b-td>
          <b-td>{{ item.breadcrumb }}</b-td>
          <b-td>{{ convertOption(item.type) }}</b-td>
          <b-td>{{ item.content }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import service from '../../service'
export default {
  props:{
    items: {type: Array, default: null}
  },
  methods:{
    convertLocation(data){
      return service.convertLocation(data)
    },
    convertOption(data){
      return service.convertOption(data)
    }
  }
}
</script>