<template>
  <div>
    <b-row class="d-flex align-items-center">
      <b-col md="2">
        <b-form-group label="Language" label-for="language">
          <b-form-select
            v-model="dataForm.language"
            id="language"
            :options="language_options"
          />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Feedback Area" label-for="feedback-area">
          <b-form-select
            v-model="dataForm.location"
            id="feedback-area"
            :options="location_options"
          />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Option" label-for="option">
          <b-form-select
            v-model="dataForm.type"
            id="option"
            :options="type_options"
          />
        </b-form-group>
      </b-col>
      <b-col md="5">
        <datetime-picker :config="{ enableTime: true, altFormat: 'd/m/Y, H:i:S', dateFormat: 'Z', mode: 'range'}"
          v-model="dataForm.time_range"
        />
      </b-col>
      <b-col>
        <b-button @click="search" class="mt-0 mt-md-2 mr-1" variant="primary">
          Search
        </b-button>
        <b-button @click="exportData" class="mt-0 mt-md-2 mr-1" variant="success">
          Export
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
export default {
  components:{
    DatePicker
  },
  data() {
    return {
      dataForm: {
        time_range: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)]
      },
      language_options: [
        {text: "All", value: null},
        {text: "VI", value: "vi"},
        {text: "TH", value: "th"},
        {text: "ID", value: "id"},
        {text: "EN", value: "en"},
      ],
      location_options: [
        {text: "All", value: null},
        {text: "Arena (new)", value: 1},
        {text: "Pronun", value: 2},
        {text: "Breaking", value: 3},
        {text: "Classic Arena", value: 4},
      ],
      type_options: [
        {text: "All", value: null},
        {text: "Error in original text", value: 1},
        {text: "Inaccurate or ineffective score", value: 2},
        {text: "Loading slowly", value: 3},
        {text: "Not user-friendly", value: 4},
        {text: "Opponent report", value: 5},
        {text: "Others", value: 6},
      ]
    };
  },
  methods: {
    search() {
      this.$emit("getList", JSON.parse(JSON.stringify(this.dataForm)));
    },
    exportData(){
      this.$emit("exportData");
    }
  },
};
</script>
