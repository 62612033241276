<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="User Report" class="mb-3" />
      </b-col>
    </b-row>
    <div class="mb-2">
      <user-report-search-form @exportData="exportData" @getList="getList" />
    </div>
    <b-card>
      <user-report-table :items="items" />
    </b-card>
    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>
<script>
import service from "../service"
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import _ from 'lodash'
import UserReportTable from './_components/UserReportTable.vue'
import UserReportSearchForm from './_components/UserReportSearchForm.vue'
import XLSX from "xlsx"
export default {
  components:{
    DatePicker,
    UserReportTable,
    UserReportSearchForm
  },
  data(){
    return{
      items: null,
      current_page: 1,
      items_perpage: 20,
      total_items: null,
      export_data: null,
      query: {
        '$and':[
          {createdAt: {$gte: new Date(new Date().getFullYear(), new Date().getMonth(), 1)}},
          {createdAt: {$lte: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)}},
        ]
      }
    }
  },
  watch: {
    current_page() {
      this.getList();
    },
  },
  created(){
    this.getList();
  },
  methods: {
    async getList(query = null){  
      if (query){
        this.query = query
      }
      if (this.query.time_range){
        this.query.time_range = this.query.time_range.split(" to ");
        this.query['$and'] = [
          {createdAt: {$gte: this.query.time_range[0]}},
          {createdAt: {$lte: this.query.time_range[1]}},
        ]
        delete this.query.time_range
      }
      _.debounce(async () => {
        let response = await service.getList({
          page: this.current_page,
          limit: this.items_perpage,
          query: JSON.stringify(this.query)
        });
        if (response.data.type === "DATA") {
          this.items = response.data.data.list;
          this.total_items = response.data.data.total;
        }
      }, 600)()
    },
    async exportData(){
      let response = await service.getList({
        page: 1,
        limit: 9000,
        query: JSON.stringify(this.query)
      });
      if (response.data.type === "DATA") {
        this.export_data = response.data.data.list;
      }
      this.export_data = this.export_data.map((result)=>{
        return {
          date: result.createdAt,
          location: this.convertLocation(result.location),
          breadcrumb: result.breadcrumb,
          type: this.convertOption(result.type),
          content: result.content
        }
      })
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.json_to_sheet(this.export_data, { origin: 'A2', skipHeader: true });
      const header = [['Date','Phần feedback','Vị trí màn','Option','Mô tả']];
      XLSX.utils.sheet_add_aoa(ws, header);
      XLSX.utils.book_append_sheet(wb, ws, 'Data');
      XLSX.writeFile(wb, `user_report.xlsx`);
      this.export_results = [];
    },
    convertLocation(data){
      return service.convertLocation(data)
    },
    convertOption(data){
      return service.convertOption(data)
    }
  }
}
</script>